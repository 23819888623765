
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CoursesMonthChart from "./CoursesMonthChart.vue";
import CoursesSubjectChart from "./CoursesSubjectChart.vue";
import { AxiosRequestConfig } from "axios";
import { VueEditor } from "vue3-editor";
import { event } from "vue-gtag";
import { useStore } from "vuex";

interface Subject {
  _id: string;
  name: string;
}

interface Classroom {
  _id: string;
  name: string;
}

interface Teacher {
  _id: string;
  name: string;
}

interface Course {
  _id: string;
  title: string;
  createdAt: string;
  subject: Subject;
  classrooms: Classroom[];
  module: string;
  teacher: Teacher;
  status: string;
  reviewDate: string;
  reviewNote: string;
}

export default defineComponent({
  name: "advisor-courses",
  components: { CoursesMonthChart, CoursesSubjectChart, VueEditor },

  setup() {
    const { t } = useI18n();

    const courses = ref<Course[]>([]);
    const filteredCourses = ref<Course[]>([]);
    const store = useStore();

    const advisorAddExercice = computed(
      () => store.getters.currentUser.advisorAddExercice
    );

    const selectedCourse = ref<Course>({
      _id: "",
      title: "",
      createdAt: "",
      subject: {
        _id: "",
        name: "",
      },
      classrooms: [],
      module: "",
      teacher: {
        _id: "",
        name: "",
      },
      status: "",
      reviewDate: "",
      reviewNote: "",
    });

    const status = ref("notValidated");
    const reason = ref("");

    const search = ref<string>("");
    const selectedClass = ref<string>("");
    const selectedTeacher = ref<string>("");

    const subjects = ref<Subject[]>([]);
    const classrooms = ref<Classroom[]>([]);
    const teachers = ref<Teacher[]>([]);

    const selectedSubject = ref<string>("");
    const loading = ref(true);
    const validateStat = ref<number>(0);

    ApiService.get("/lms/advisor/courses")
      .then(({ data }) => {
        data.forEach((course: Course) => {
          courses.value.push(course);

          if (!subjects.value.find((s: Subject) => s._id == course.subject._id))
            subjects.value.push(course.subject);

          if (!teachers.value.find((t: Teacher) => t._id == course.teacher._id))
            teachers.value.push(course.teacher);

          for (const classroom of course.classrooms) {
            if (
              !classrooms.value.find((c: Classroom) => c._id == classroom._id)
            )
              classrooms.value.push(classroom);
          }
        });
        filteredCourses.value = [...courses.value];
      })
      .catch((e) => console.log(e))
      .finally(() => {
        loading.value = false;
      });

    const filterCourses = () => {
      currentPage.value = 1;
      filteredCourses.value = courses.value.filter((course) => {
        const toSearch = search.value.toLowerCase();
        return (
          course.title.toLowerCase().includes(toSearch) &&
          (selectedClass.value == "" ||
            course.classrooms.find((c) => c._id == selectedClass.value)) &&
          (course.subject._id == selectedSubject.value ||
            selectedSubject.value == "") &&
          (course.teacher._id == selectedTeacher.value ||
            selectedTeacher.value == "") &&
          ((course.status === "validated" && validateStat.value == 1) ||
            (course.status === "notValidated" && validateStat.value == 2) ||
            (course.status === "rejected" && validateStat.value == 3) ||
            validateStat.value == 0)
        );
      });
    };
    const getClassroomCourses = (id: string) => {
      return courses.value.filter((course: Course) =>
        course.classrooms.find((c) => c._id == id)
      ).length;
    };

    onMounted(() => {
      setCurrentPageTitle(t("course.coursesValidation"));
    });

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredCourses.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredCourses.value.slice(startIndex - 1, endIndex);
    });

    const saveReview = () => {
      Swal.fire({
        title:
          status.value == "rejected"
            ? t(`course.confirmRejectCourse`)
            : t(`course.confirmValidationCourse`),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: status.value == "rejected" ? "#ff3333" : "#47BE7D",
        cancelButtonColor: "#3085d6",
        confirmButtonText:
          status.value == "rejected"
            ? t(`course.acceptReject`)
            : t(`course.acceptValidate`),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.patch(
            "/lms/advisor/reviewCourse/" + selectedCourse.value._id,
            {
              status: status.value,
              reviewNote: reason.value,
            } as AxiosRequestConfig
          )
            .then(() => {
              event("Review course", {
                event_category: "Course",
                event_label: "Course section",
                value: 1,
              });

              Swal.fire(
                status.value == "rejected"
                  ? t(`course.rejected`)
                  : t(`course.validated`),
                status.value == "rejected"
                  ? t("course.rejectedCourseInfo")
                  : t("course.validatedCourseInfo"),
                "success"
              ).then(() => {
                selectedCourse.value.status = status.value;
                selectedCourse.value.reviewDate = new Date().toString();
              });
            })
            .catch((e) => console.log(e));
        }
      });
    };

    return {
      t,
      courses,
      search,
      moment,
      filterCourses,
      filteredCourses,
      selectedClass,
      getClassroomCourses,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      subjects,
      selectedSubject,
      loading,
      classrooms,
      teachers,
      selectedTeacher,
      saveReview,
      validateStat,
      selectedCourse,
      status,
      reason,
      advisorAddExercice,
    };
  },
});
